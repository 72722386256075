window.sliderTimer5 = function sliderTimer5() {
    this.setInterval(currentSlideuniteauto, 4000);
}
window.addEventListener('load', () => {
    sliderTimer5();
})
window.currentSlideuniteauto = function currentSlideuniteauto() {
    var firstBlock = document.getElementById('first');
    var secondBlock = document.getElementById('second');
    var thirdBlock = document.getElementById('third');


    var oneBlock = document.getElementById('1');
    var twoBlock = document.getElementById('2');
    var threeBlock = document.getElementById('3');

    var firstdiv = document.getElementById("swipeChoose").firstElementChild;
    var lastdiv = document.getElementById("swipeChoose").lastElementChild;
    var listachievement = document.getElementById("swipeChoose"); // Get the <ul> element to insert a new node
    lastdiv.insertAdjacentElement("afterend", firstdiv);
    var idchoose = listachievement.children[0].id;

    var isPaused = false;

    if (!window.isPaused) {

        if (idchoose === "first") {
            firstBlock.style.display = 'flex';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';


            oneBlock.classList.add('dotactiveS');
            twoBlock.classList.remove('dotactiveS');
            threeBlock.classList.remove('dotactiveS');



        } else if (idchoose === "second") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'flex';
            thirdBlock.style.display = 'none';

            oneBlock.classList.remove('dotactiveS');
            twoBlock.classList.add('dotactiveS');
            threeBlock.classList.remove('dotactiveS');


        } else if (idchoose === "third") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'flex';


            oneBlock.classList.remove('dotactiveS');
            twoBlock.classList.remove('dotactiveS');
            threeBlock.classList.add('dotactiveS');


        }
    }
}
window.currentSlideunite = function currentSlideunite(id) {
    var firstBlock = document.getElementById('first');
    var secondBlock = document.getElementById('second');
    var thirdBlock = document.getElementById('third');


    var oneBlock = document.getElementById('1');
    var twoBlock = document.getElementById('2');
    var threeBlock = document.getElementById('3');

    var isPaused = false;
    window.isPaused = true;

    setTimeout(() => {
        window.isPaused = false;
    }, 15000);


    if (id === '1') {
        firstBlock.style.display = 'flex';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';


        oneBlock.classList.add('dotactiveS');
        twoBlock.classList.remove('dotactiveS');
        threeBlock.classList.remove('dotactiveS');



    } else if (id === '2') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'flex';
        thirdBlock.style.display = 'none';

        oneBlock.classList.remove('dotactiveS');
        twoBlock.classList.add('dotactiveS');
        threeBlock.classList.remove('dotactiveS');


    } else if (id === '3') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'flex';


        oneBlock.classList.remove('dotactiveS');
        twoBlock.classList.remove('dotactiveS');
        threeBlock.classList.add('dotactiveS');


    }

}